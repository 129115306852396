export default {
  headline: "Software Engineer",
  intro: `Sujan is a Software Engineer.He has a Master of Engineering Management degree from La Trobe University and bachelor’s degree in information science and engineering from Jain University. As a developer he has been building website and softwares from many years .He is the creator of Blogue. He is Interested in Cryptography and Building secure and scalable platforms.He writes Technical blogs on Blogue,Medium & Hackernoon. He is a Postman Student Leader and a Postman Student Expert. He has been an active open source contributer. He has won the Hackernoon Contributor of the Year 2020 award in category FUTURE.
  
  Key Skills – C | C++ | Java | JavaScript | TypeScript | Python | Node.js | React.js | Next.js | MongoDB | SQL | GitHub | Git | JEST | Puppeteer | Docker | Netlify | Heroku | Digital Ocean | Firebase | Microsoft 365 | Ubuntu | Google Cloud.`,
  facts: [
    "DOB:17th March 1999",
    "Nationality:Nepali ",
    "Country:$1",
    "Mother-tongue:Nepali",
    "Other Languages:English,Hindi",
    "Address:Kathmandu,Nepal",
  ],
  trivias: [
    "Favorite sport:Football",
    "Passionate about writing",
    `Loves to read books`,
    `Gamer`,
    `$6`,
    `$5`,
    `$7`,
    `$2 , $3 , $4`,
  ],
  links: {
    $1: ["Nepal", "https://en.wikipedia.org/wiki/Nepal"],
    $2: ["Blogue", "https://blogue.tech/@/sujanchhetri"],
    $3: ["Medium", "https://medium.com/@suJJJan"],
    $4: ["Hackernoon", "https://hackernoon.com/u/sujan-chhetri"],
    $5: [
      "Postman student expert",
      "https://badgr.com/public/assertions/96g4_3DiTq2dxFwWTrC7Dw",
    ],
    $6: [
      "Hackernoon Contributor of the Year",
      "https://noonies.tech/award/hacker-noon-contributor-of-the-year-future",
    ],
    $7: [
      "Postman student Leader",
      "https://badgr.com/public/assertions/0cvwCXgXTUmFMPPiy8ANaA",
    ],
  },
};
