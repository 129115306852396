import React from "react";
import "./Education.scss";
import "./Skills.scss";
import Window from "../components/Window";
import Skill from "../components/Skill";
import { useLocation } from "react-router-dom";
import pages from "../pages";
import {education,certificate,links,publication,skills} from "../data/resume";
import { parseLinks } from "../_utils";

function Education() {
  const { pathname } = useLocation();
  return (
    <Window title={pages[pathname.slice(1)].title} bodyClass="window__resume">
      <hr/>
      <h2 className="text-center">Education</h2>
      <hr/>
    {education.map((resume, i) => (
        <div key={i} className="container">
          <div className="icon">{React.createElement(resume.icon)}</div>
          <div className="time">{resume.time}</div>
          <div className="degree">{resume.title}</div>
          <div className="place">{resume.place}</div>
          <div className="description">{resume.description}</div>
        </div>
      ))}
      <hr/>
      <h2 className="text-center">Certifications</h2>
      <hr/>
      {certificate.map((resume, i) => (
        <div key={i} className="container">
          <div className="icon">{React.createElement(resume.icon)}</div>
          <div className="time">{resume.time}</div>
          <div className="degree">{parseLinks(resume.title,links)}</div>
          <div className="place">{resume.place}</div>
          <div className="description">{resume.description}</div>
        </div>
      ))}
      <hr/>
      <h2 className="text-center">Publications</h2>
      <hr/>
    {publication.map((resume, i) => (
        <div key={i} className="container">
          <div className="icon">{React.createElement(resume.icon)}</div>
          <div className="time">{resume.time}</div>
          <div className="degree">{parseLinks(resume.title,links)}</div>
          <div className="place">{resume.place}</div>
          <div className="description">{resume.description}</div>
        </div>
      ))}
      <br/><br/><br/>
      <hr/>
      <h2 className="text-center">Skills,Tools & Technologies</h2>
      <hr/>
      {Object.keys(skills).map((category) => (
        <div key={category} className="col">
          <div className="title">
            <big>{skills[category].label}</big>
          </div>
          <div className="skills">
            {skills[category].skills.map((skill) => (
              <Skill key={skill.name} data={skill} />
            ))}
          </div>
        </div>
      ))}
    </Window>
  );
}

export default Education;
