import { Logo, Icon } from "../assets";

export default {
  "Selected Works": [
    {
      title: "$1",
      logo: Logo.Fandom,
      createdAt: "2020",
      description: `A Blog writing Platform`,
      Features: [
        "Authentication",
        "Profile generation",
        "Blog Writing",
        "Tags,Categories",
        "Sharing",
        "Donation",
        "Likes,Commenting"
      ],
      techs: "Node.js,Express.js,React.js,MongoDB,Next.js",
      links: {
        $1: [
          "Blogue",
          "https://blogue.tech",
        ],
      },
    },
    {
      title: "$2",
      logo: Logo.FandomDev,
      createdAt: "2020",
      description: "An Ai based news app",
      Features: [
        "Ai bot",
        "speech recognition",
        "small talk",
        "News of every category",
      ],
      techs: "React.js,Alan-ai,reactstrap,NewsApi",
      links: {
        $2: ["NewsHub", "https://newshubatblogue.netlify.app/"],
      },
    },
    {
      title: "$3",
      logo: Logo.Win7,
      createdAt: "2020",
      description: "A hub for Varities of project along with tutorial and source code",
      Features: [
        "Project tutorial",
        "source code",
        "only I can create and delete a project",
        "Project submission",
        "Like",
      ],
      techs:
        "React.js,Node.js,MongoDB,Material-Ui",
      links: {
        $3: [
          "ProjectHub","https://projecthubatblogue.netlify.app/",
        ],
      },
    },
    {
      title: "$4",
      logo: Logo.Bosch,
      createdAt: "2020",
      description: "A base for movies",
      Features: [
        "Authentication",
        "Favourite",
        "Commenting,Like",
      ],
      techs: "React.js,Node.js,MongoDB,MovieApi",
      links: {
        $4: ["MovieBase", "https://basemovie.herokuapp.com/"],
        
      },
    },
    {
      title: "$5",
      logo: Logo.LuckyDraw,
      createdAt: "2020",
      description: "A Chat app like slack",
      Features: [
        "Authentication",
        "Favourite channel",
        "channel creation",
        "Private Chat",
        "File Upload",
      ],
      techs: "React.js,Firebase,Material-Ui",
      links: {
        $5: ["SlackItUp", "https://slackitupbro.web.app"],
        
      },
    },
    {
      title: "Others",
      createdAt: "2019 - 2020",
      description: "Other works",
      others: [
        "$6",
        "$7",
        "$8",
        "$9",
        "$10",
        "$11",
        "$12",
        "$13",
        "$14",
        "$15",
        "$16"
      ],
      links: {
        $6: [
          "Bitchain",
          "https://bitblockchain.herokuapp.com/",
        ],
        $7: [
          "Facemotion Detector",
          "https://facemotion.netlify.app/",
        ],
        $8: [
          "Encrypto",
          "https://dencrypto.netlify.app/",
        ],
        $9: [
          "Xoxu",
          "https://xoxu.herokuapp.com/",
        ],
        $10: [
          "WeatherPiea",
          "https://weatherpiea.herokuapp.com/",
        ],
        $11: [
          "NewsScript",
          "https://sujanchhetri.github.io/NewsScript/",
        ],
        $12: [
          "TicTacToe",
          "https://tictaktoae.herokuapp.com/",
        ],
        $13: [
          "BlogEditor",
          "https://reactblogeditor.netlify.app/",
        ],
        $14: [
          "GmapClone",
          "https://gmapclone.netlify.app/",
        ],
        $15: [
          "Portfolio",
          "https://sujjjan.ml/",
        ],
        $16: [
          "PasswordGen",
          "https://sujanchhetri.github.io/passwordgenerator/",
        ],

      },
    },
  ],
};
