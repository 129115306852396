const r = require.context("./assets");
const assets = r.keys().map(r);
export const Avatar =
  "https://avatars1.githubusercontent.com/u/44917558?s=460&u=9699385a6a1c3434bb18e5e893af0fbffa82a507&v=4y";

export const Icon = (key, type = "Flat") => {
  let _type = type.toLowerCase();
  _type = _type !== "Flat" && _type !== "neumorphism" ? _type : "Flat";
  return assets
    .filter((icon) => icon.includes(_type))
    .find((icon) => icon.includes(key));
};

export const Social = {
  Github: require("./assets/github.webp"),
  Linkedin: require("./assets/linkedin.webp"),
  facebook:require("./assets/facebook.webp"),
  twitter:require("./assets/twitter.webp"),
  instagram:require("./assets/instagram.webp"),
  youtube:require("./assets/youtube.webp"),
  anchor:require("./assets/anchor.webp"),
  sniphub:require("./assets/sniphub.webp"),

};

export const Logo = {
  Bosch: require("./assets/logo-bosch.webp"),
  Cathay: require("./assets/logo-cathay.webp"),
  Fandom: require("./assets/logo-fandom.webp"),
  FandomDev: require("./assets/logo-fandom-dev.webp"),
  Win7: require("./assets/logo-win7.webp"),
  LuckyDraw: require("./assets/logo-luckydraw.webp"),
};

export const themes = {
  Flat: require("./assets/theme-flat.webp"),
  Neumorphism: require("./assets/theme-neumorph.webp"),
  Classic: require("./assets/theme-classic.webp"),
};
