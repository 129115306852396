import { FaLaptop } from "react-icons/fa";

export const blogs = [
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Wednesday, September 29th 2021",
    title: "$58",
    description:
      "Millions of business enterprises are competing to set a foothold in the competitive market. As technology trends are changing fast, businesses look at the mobile and web application sectors to reach their audience and serve them. Technological advances and the availability of app development tools and technologies ...",
  }, {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Tuesday, September 21st 2021",
    title: "$57",
    description:
      "If you're starting your career as a developer or you have several years of experience, there are many aspects in which you need to gain proficiency in order to advance in your career and become a marketable programmer regardless of your tech stack. Learning tech skills and improving your soft skills is one of the ...",
  }, {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, September 4th 2021",
    title: "$56",
    description:
      "The founders of the internet envisioned the internet as a decentralized entity. But over time internet traffic has become concentrated on a few websites. According to Ahrefs.com, most of the global internet traffic goes to a few websites. Websites like YouTube, Facebook, and Amazon get the most of it. Every ...",
  }, {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, August 21st 2021",
    title: "$55",
    description:
      "More than 2 billion people in over 180 countries use WhatsApp to stay in touch with friends and family, anytime and anywhere. WhatsApp is free and offers simple, secure, reliable messaging and calling, available on phones all over the world. And yes, the name WhatsApp is a pun on the phrase What's Up.WhatsApp ...",
  },{
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Monday, August 9th 2021",
    title: "$54",
    description:
      "In this tutorial, we will be building a simple node.js app that will download website source code files including HTML, CSS, js, and images of the website from URL in node.js. For this, we will be using ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Thursday, August 5th 2021",
    title: "$53",
    description:
      "Git is software that keeps track of changes that you make to files and directories. And it's especially good at keeping track of text changes. Let's imagine that you have a document. You start with version 1 of that document. You make some changes to it, now you have version 2. And you make some more changes and you ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, July 24th 2021",
    title: "$52",
    description:
      "A linked list is a data structure composed of nodes. Each node has at least 2 components, the data that it holds and a reference to the next node in the list (called a pointer). The first node in a linked list is called the head, the last node in a linked list is called the tail. There are several ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Tuesday, July 13th 2021",
    title: "$51",
    description:
      "Technology keeps improving, and websites, too. There are more tools and options available for companies to develop great websites. And backend developers, which are responsible for the server-side of the platform, have a rich amount of tools that help them improve and offer the user the best experience possible. ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Friday, July 2nd 2021",
    title: "$50",
    description:
      "Microsoft Office 2013 (also called Office 2013 and codenamed Office 15) is a version of Microsoft’s popular office suite and the successor to Office 2010. The package was presented on July 16, 2012. Office 2013 is available as part of Windows RT for ARM processors and separately for x86 and x64 versions of Windows. ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Sunday, June 6th 2021",
    title: "$49",
    description:
      "Wondering what's all the hype behind the success of Node.js development for building Applications? There are several reasons. It simplifies web application development in every way and gives a lot of options to Node.js developers. It’s an ideal choice for companies looking to commit to a real-time application and ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Sunday, May 16th 2021",
    title: "$48",
    description:
      "Microsoft’s free upgrade offer for Windows 7 and Windows 8.1 users ended a few years ago, but you can still technically upgrade to Windows 10 free of charge. While Microsoft has removed the  ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Friday, May 14th 2021",
    title: "$47",
    description:
      "Security is one of the vital concerns especially in the current period with an extensive rise in the usage of the internet. So, an effective text encryption algorithm is of greater need for achieving an immense amount of privacy. In this context, we propose a secure text encryption algorithm using an enhanced ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, April 24th 2021",
    title: "$46",
    description:
      "Testing is one of the most important aspects of software production. Tests inform developers on the clear behavior of what certain parts of the application should accomplish. Many companies use a programming methodology known as Test-Driven Development (TDD) to guide production. TDD dictates that automated unit  ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Tuesday, April 13th 2021",
    title: "$45",
    description:
      "Puppeteer is a Node library which provides a high-level API to control Chrome or Chromium over the DevTools Protocol. Puppeteer runs headless by default, but can be configured to run full (non-headless) Chrome or Chromium. Learn more about puppeteer ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Sunday, March 21st 2021",
    title: "$44",
    description:
      "GraphQL is an application layer server-side technology that is developed by Facebook for executing queries with existing data. GraphQL can optimize RESTful API calls. It gives a declarative way of fetching and updating your data. GraphQL ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, March 13th 2021",
    title: "$43",
    description:
      "Git is a specific open-source version control system created by Linus Torvalds in 2005. Specifically, Git is a distributed version control system, which means that the entire codebase and history are available on every developer’s computer, which allows for easy branching and merging. Why use something like Git? Say ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Sunday, March 7th 2021",
    title: "$42",
    description:
      "Nginx is an open-source, fast, lightweight, and high-performance web server that can be used to serve static files. NGINX is considered the popular web server behind Apache web server and Microsoft's IIS.NGINX improves content and application delivery, improves security, and facilitates scalability and availability ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Sunday, February 21st 2021",
    title: "$41",
    description:
      "Encryption means decoding or locking the data to protect it from unauthorized access. It makes the plaintext data an unreadable form that doesn’t make sense to the reader. The encrypted data looks like a string of random alphabets and digits. Complex mathematical algorithms and digital keys are used to ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, February 6th 2021",
    title: "$40",
    description:
      "In the early days of the web, anyone who wanted to build a web application had to own the physical hardware required to run a server, which was complex and expensive. Then cloud computing came, where fixed numbers of servers or amounts of server space could be rented or purchased remotely. Developers and companies ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, January 30th 2021",
    title: "$39",
    description:
      "A proxy lets you go online under a different IP address identity. You don’t change your Internet provider; you simply go online and search for “free proxies” or “list of proxies” and you will get several websites that provide lists of free proxies. A reverse proxy is a server that sits in front of web servers and ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, January 23rd 2021",
    title: "$38",
    description:
      "HTTP stands for Hypertext Transfer Protocol. It offers set of rules and standards which govern how any information can be transmitted on the World Wide Web. It provides standard rules for web browsers & servers to communicate. It is an application layer network protocol that is built on top of TCP. It uses ...",
  },
  {
    icon: FaLaptop,
    icon: FaLaptop,
    time: "Saturday, January 16th 2021",
    title: "$37",
    description:
      "An API (Application Programming Interface) is a set of functions that allows applications to access data and interact with external software components, operating systems, or microservices. API lets a developer make a specific “call” or “request” in order to send or receive information. This communication is ...",
  },
  {
    icon: FaLaptop,
    time: "Sunday, January 3rd 2021",
    title: "$36",
    description:
      "Cryptography is the fundamental technology used to protect information as it travels over the Internet. Every day, encryption is used to protect the content of web transactions, email, newsgroups, chat, web conferencing, and telephone calls as they are sent over the Internet. Without encryption any crook, thief, ...",
  },
  {
    icon: FaLaptop,
    time: "Sunday, December 27th 2020",
    title: "$35",
    description:
      "Node.js technology is ten years old. It is an open source JavaScript runtime environment which allows building JavaScript programs for the server-side. It uses V8 JavaScript Engine, an important integration that once increased Node popularity and upgraded it to the next level very quickly. V8 engine which powers ...",
  },
  {
    icon: FaLaptop,
    time: "Sunday, December 20th 2020",
    title: "$34",
    description:
      "Docker is an open platform for developing, shipping, and running applications. Docker enables you to separate your applications from your infrastructure so you can deliver software quickly. With Docker, you can manage your infrastructure in the same ways you manage your applications. By taking advantage of ...",
  },
  {
    icon: FaLaptop,
    time: "Sunday, December 13th 2020",
    title: "$33",
    description:
      "Terminal is a interface or say a command line interface where user can enter commands to interact with operating system. There are many CLI tools based on OS and as per their features but the functionality is same i.e to ease the interaction of user to Operating system. As being a windows user you may use cmd or ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, December 11th 2020",
    title: "$32",
    description:
      "Go and Rust are compiled languages, both are open source, and both are designed for modern, microservices-oriented, parallel computing environments.Rust and Go are two of the industry’s biggest successes when it comes to modern programming language development. Both languages compete in terms of backend web development ...",
  },
  {
    icon: FaLaptop,
    time: "Tuesday, December 8th 2020",
    title: "$31",
    description:
      "These days there are so many career choices to take in the programmer world. But one of the most successful and important ones is Web Development . There are so many companies out there that are on the constant look for web developers and designers. And the good thing is that these companies and businesses are ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, December 2nd 2020",
    title: "$30",
    description:
      "Ubuntu is a Linux distribution based on Debian and mostly composed of free and open-source software. Ubuntu is officially released in three editions: Desktop, Server, and Core for Internet of things devices and robots.Ubuntu is a complete Linux operating system, freely available with both community and professional ...",
  },
  {
    icon: FaLaptop,
    time: "Sunday, November 29th 2020",
    title: "$29",
    description:
      "Telegram is a messaging app with a focus on speed and security, it’s super-fast, simple and free. You can use Telegram on all your devices at the same time — your messages sync seamlessly across any number of your phones, tablets or computers.Telegram Bots are accounts operated by software – not people – and they'll ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, November 27th 2020",
    title: "$28",
    description:
      "Learning is a process.It takes time and good materials helps a lot in the learning process. Being a web developer there are many resources to follow up and learn. Till date learning web development has become a popular and trending path. Googling and Youtube surfing from good content creators has ease the process to ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, November 25th 2020",
    title: "$27",
    description:
      "Code commenting is the practice of sprinkling short, normally single-line notes throughout your code. These notes are called comments. They explain how your program works, and your intentions behind it.Comments don’t have any effect on your program, but they are invaluable for people reading your code ...",
  },
  {
    icon: FaLaptop,
    time: "Tuesday, November 24th 2020",
    title: "$26",
    description:
      "After almost two years, the wait is nearly over. The API has been frozen, and the countdown to Deno 1.0, officially scheduled for release on May 13, has begun.Due to its famous creator and forward-thinking vision, Deno is sure to be the most exciting and controversial JavaScript-related release in recent ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, November 13th 2020",
    title: "$1",
    description:
      "Facemotion detection can be seen in Machine Learning projects with python and Tensorflow. As a web application most of us haven't seen or even don't know that it exists. Yes we can create our own face motion detection web app with face-api that is written on top of tensorflow.js. We can detect the face emtion just ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday,October 14th 2020",
    title: "$2",
    description:
      "JavaScript is a lightweight, interpreted, object-oriented language with first-class functions, and is best known as the scripting language for Web pages, but it's used in many non-browser environments as well. It is a prototype-based, multi-paradigm scripting language that is dynamic, and supports object-oriented, ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, October 9th 2020",
    title: "$3",
    description:
      "In the modern world, websites have turned out to be very crucial and every single company or business or even individuals own websites. The huge demands of commercial enterprises make it compulsory for every company to have a site or web app. The quality of the application or website plays a ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, October 7th 2020",
    title: `$4`,
    description:
      "Web hosting is a service that allows organizations and individuals to post a website or web page onto the Internet. A web host, or web hosting service provider, is a business that provides the technologies and services needed for the website or webpage to be viewed in the ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, October 5th 2020",
    title: `$5`,
    description:
      "Node.js technology is ten years old. It is an open source JavaScript runtime environment which allows building JavaScript programs for the server-side. It uses V8 JavaScript Engine, an important integration that once increased Node popularity and upgraded it to the next level very quickly. V8 engine which powers ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, October 5th 2020",
    title: `$6`,
    description:
      "MongoDB is a NoSQL database. It is different from traditional SQL based databases. How is it different ? First, it doesn't have a predefined structure like SQL tables. We don't need to define how our table will look like before hand. ...",
  },
  {
    icon: FaLaptop,
    time: "Saturday, October 3rd 2020",
    title: `$7`,
    description:
      "Git is a specific open-source version control system created by Linus Torvalds in 2005. Specifically, Git is a distributed version control system, which means that the entire codebase and history are available on every developer’s ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, October 2nd 2020",
    title: `$8`,
    description:
      "HTTP status codes are responses issued for a client’s request made to a server. For example, when your client (your web browser) tries to connect to your site (the server).Based on how the request is handled, the server shows different responses. These responses include redirects, server errors, client ...",
  },
  {
    icon: FaLaptop,
    time: "Thursday, October 1st 2020",
    title: `$9`,
    description:
      "As a beginner to the Javascript world you might get confused where to start. You 'll go through youtubes and blog post like this and figure out that the best idea to learn javascript is by creating small projects. Thats applies to every programming language. Here are some few project ideas and the list of project ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, September 25th 2020",
    title: `$10`,
    description:
      "Windows 10 has now become the world’s most popular operating system as 400 million users. Windows 10 was released on July 29, 2015, and it comes with great new features that let you do things easily and fast.Microsoft has continued to its winning formula by revealing Windows 10. To activate Windows 10, you need a ...",
  },
  {
    icon: FaLaptop,
    time: "Thursday, September 24th 2020",
    title: `$11`,
    description:
      "Programming languages and computer coding have made life simpler for us. Whether it’s automobiles, banks, home appliances, or hospitals, every aspect of our lives depends on codes. No wonder, coding is one of the core skills required by most well-paying jobs today. Coding skills are especially of value in the IT, ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, October 28th 2020",
    title: `$12`,
    description:
      "There are many themes available in VS code marketplace. Not many of us know how they are created and are published.In this tutorial we will learn to create a VS code theme and Publish it in the VS code marketplace and use it as an extension in the editor. It's simple and easy. Just follow the steps and you'll be all ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, September 11th 2020",
    title: `$13`,
    description:
      "Blockchain is a distributed & decentralized ledger that stores data and is publicly shared across all the node of its network. Blockchain is almost always used instead of the terms Bitcoin and cryptocurrency. And there are many other places this technology can be used. We are beginning to barely scratch the ...",
  },
  {
    icon: FaLaptop,
    time: "Saturday, August 29th 2020",
    title: `$14`,
    description:
      "Do you have a Node.js interview coming up?You should prepare for a wide range of potential questions regarding Node.js directly and anything related to JavaScript or general programming architecture topics.To help you out with this, we've created a huge list of questions and answers to help you prepare ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, October 28th 2020",
    title: `$15`,
    description:
      "Node.js is popularly though of as JavaScript on the backend, but that's a very limited and inaccurate description. Node.js is much more than that; it is a general purpose programming platform which can be used for developing all sorts of programs - from web servers, to CMS, to ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, October 28th 2020",
    title: `$16`,
    description:
      "Most of us are familiar with Twitter. But we are not much familiar that we can automate the activities like status posting,retweeting,liking,commenting and so on. So,here I'll show you how we can automate some of the activities like getting the twitter data,posting the status and retweeting with ...",
  },
  {
    icon: FaLaptop,
    time: "Friday, July 3rd 2020",
    title: `$17`,
    description:
      "The Searching Algorithm can be implemented with other languages as well. But here we will work with JavaScript to solve the Searching Algorithms. These algorithms are used to handle or work with the search related task in our projects or any applications that require searching. Searching is made easier by these ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, June 29th 2020",
    title: `$18`,
    description:
      "Every programming problem involves some kind of algorithm. Algorithm is a process or set of steps to accomplish a certain tasks or a step by step way to solve a problem. There are about 700 programming languages. Here is a ...",
  },
  {
    icon: FaLaptop,
    time: "Thursday, June 18th 2020",
    title: `$19`,
    description:
      "If you are reading this blog then you already know about github. Github is a web-based Git repository hosting service that primarily offers ...",
  },
  {
    icon: FaLaptop,
    time: "Thursday, June 18th 2020",
    title: `$20`,
    description:
      "While building any web app people get confused or feel difficulty in authentication process. Creating a registration form and sign in process is a hectic process if we don't follow proper method.In this tutorial we will see how we can register and login using ...",
  },
  {
    icon: FaLaptop,
    time: "Wednesday, October 28th 2020",
    title: `$21`,
    description:
      "In the world full of Siri, Cortana & Alexa, have you ever wondered you can create a new friend of yours. Well it might not be that intelligent but it not worthless to try creating something new. With the current state of web apps, we can rely on various UI elements to interact with users. With the Web Speech ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, June 15th 2020",
    title: `$22`,
    description:
      "ES6 came with many new features, but one of the best features was the official introduction of Promises. Promises allow you to write clean non-callback-centric code without ever having to worry about callback hell. Even if you never write your own promise, knowing how they work is incredibly important, since many ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, June 15th 2020",
    title: `$23`,
    description:
      "A full-stack development professional is the one who is having sound technical knowledge of each and every aspect of development — front end, back ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, June 15th 2020",
    title: `$24`,
    description:
      "And slowly github became the new facebook for coders where instead of posting pictures and life events people post code for projects and your ...",
  },
  {
    icon: FaLaptop,
    time: "Monday, June 15th 2020",
    title: `$25`,
    description:
      "A few days ago I was talking with a friend and he said to me that JavaScript is an old Technology that the web developers only use it because ...",
  },
];

export const links = {
  $1: [
    "Facemotion Detection App with face-api",
    "https://blogue.tech/feeds/facemotion-detection-app-with-face-api",
  ],
  $2: [
    "Error Handling in Javascript",
    "https://blogue.tech/feeds/error-handling-in-javascript",
  ],
  $3: [
    "Web App Testing Tools",
    "https://blogue.tech/feeds/web-app-testing-tools",
  ],
  $4: [
    "Deploy your WebApp for Free",
    "https://blogue.tech/feeds/deploy-your-webapp-for-free",
  ],
  $5: ["Why Node.js?", "https://blogue.tech/feeds/why-node.js"],
  $6: [
    "MongoDB Tutorial for Beginners",
    "https://blogue.tech/feeds/mongodb-tutorial-for-beginners",
  ],
  $7: [
    "Git:Developer's Favourite",
    "https://blogue.tech/feeds/git:developer's-favourite",
  ],
  $8: [
    "HTTP Status/Error Codes and How to fix them",
    "https://blogue.tech/feeds/http-statuserror-codes-and-how-to-fix-them",
  ],
  $9: [
    "JavaScript Projects for Beginners",
    "https://blogue.tech/feeds/javascript-projects-for-beginners",
  ],
  $10: [
    "Windows 10 Activation Methods",
    "https://blogue.tech/feeds/windows-10-activation-methods",
  ],
  $11: [
    "Top Programming Languages To Learn",
    "https://blogue.tech/feeds/top-programming-languages-to-learn",
  ],
  $12: [
    "Creating & Publishing a VS code Theme",
    "https://blogue.tech/feeds/creating-and-publishing-a-vs-code-theme",
  ],
  $13: [
    "A walkthrough to Blockchain",
    "https://blogue.tech/feeds/a-walkthrough-to-blockchain",
  ],
  $14: [
    "Node.js Interview Prep Questions",
    "https://blogue.tech/feeds/node.js-interview-prep-questions",
  ],
  $15: [
    "CLI tool with Node.js",
    "https://blogue.tech/feeds/cli-tool-with-node.js",
  ],
  $16: [
    "Twitter bot with Node.js and Deploy to Heroku",
    "https://blogue.tech/feeds/twitter-bot-with-node.js-and-deploy-to-heroku",
  ],
  $17: [
    "Searching Algorithms in JavaScript",
    "https://blogue.tech/feeds/searching-algorithms-in-javascript",
  ],
  $18: ["How to code?", "https://blogue.tech/feeds/how-to-code"],
  $19: [
    "Github Student Pack and How to get it?",
    "https://blogue.tech/feeds/github-student-pack-and-how-to-get-it",
  ],
  $20: [
    "Node Authentication using Passport.js",
    "https://blogue.tech/feeds/node-authentication-using-passport.js",
  ],
  $21: [
    "Creating A Chatbot Using Socket.io, API.AI and Web Speech API",
    "https://blogue.tech/feeds/creating-a-chatbot-using-socket.io-api.ai-and-web-speech-api",
  ],
  $22: [
    "JavaScript Promises, Callbacks, and Async/Await",
    "https://blogue.tech/feeds/javascript-promises-callbacks-and-asyncawait",
  ],
  $23: [
    "Full-Stack Web Developer: A guide to learn",
    "https://blogue.tech/feeds/full-stack-web-developer:-a-guide-to-learn",
  ],
  $24: [
    "A walk-through to Github",
    "https://blogue.tech/feeds/a-walk-through-to-github",
  ],
  $25: [
    "The Future of JavaScript Doesn't Exist?",
    "https://blogue.tech/feeds/the-future-of-javascript-doesn't-exist",
  ],
  $26: ["Deno:Intro", "https://blogue.tech/feeds/deno:intro"],
  $27: [
    "Comment Remover with Javascript",
    "https://blogue.tech/feeds/comment-remover-with-javascript",
  ],
  $28: [
    "Github Repos Every Web Developer Should follow",
    "https://blogue.tech/feeds/github-repos-every-web-developer-should-follow",
  ],
  $29: [
    "Telegram bot with Node.js and Deploy to Heroku",
    "https://blogue.tech/feeds/telegram-bot-with-node.js-and-deploy-to-heroku",
  ],
  $30: [
    "Dual Boot Ubuntu and Windows",
    "https://blogue.tech/feeds/dual-boot-ubuntu-and-windows",
  ],
  $31: [
    "A Roadmap for Web Developers",
    "https://blogue.tech/feeds/a-roadmap-for-web-developers",
  ],
  $32: [
    "Rust vs Go as Backend in Web Development",
    "https://blogue.tech/feeds/rust-vs-go-as-backend-in-web-development",
  ],
  $33: [
    "Customize Your Terminal:Mac,Linux & Windows",
    "https://blogue.tech/feeds/customize-your-terminal:maclinux-and-windows",
  ],
  $34: [
    "Dockerizing a Nodejs web app",
    "https://blogue.tech/feeds/dockerizing-a-nodejs-web-app",
  ],
  $35: [
    "How to boost Node.js Development Process?",
    "https://blogue.tech/feeds/how-to-boost-node.js-development-process",
  ],
  $36: [
    "Data Encryption in Golang",
    "https://blogue.tech/feeds/data-encryption-in-golang",
  ],
  $37: [
    "Popular APIs to Integrate in your Project",
    "https://blogue.tech/feeds/popular-apis-to-integrate-in-your-project",
  ],
  $38: [
    "Intro to http and https",
    "https://blogue.tech/feeds/intro-to-http-and-https",
  ],
  $39: [
    "Proxy and Reverse Proxy",
    "https://blogue.tech/feeds/proxy-and-reverse-proxy",
  ],
  $40: [
    "A walk through to Serverless Computing",
    "https://blogue.tech/feeds/a-walk-through-to-serverless-computing",
  ],
  $41: [
    "Symmetric vs Asymmetric Encryption",
    "https://blogue.tech/feeds/symmetric-vs-asymmetric-encryption",
  ],
  $42: ["Intro to Nginx", "https://blogue.tech/feeds/intro-to-nginx"],
  $43: [
    "Must know Git Commands for Every Developer",
    "https://blogue.tech/feeds/must-know-git-commands-for-every-developer",
  ],
  $44: ["Intro to GraphQL", "https://blogue.tech/feeds/intro-to-graphql"],
  $45: [
    "How to extract Cookie from a site with Puppeteer by logging in?",
    "https://blogue.tech/feeds/how-to-extract-cookie-from-a-site-with-puppeteer-by-logging-in",
  ],
  $46: [
    "Unit Testing with Jest",
    "https://blogue.tech/feeds/unit-testing-with-jest",
  ],
  $47: [
    "A Novel Text Encryption Algorithm using enhanced Diffie Hellman and AES",
    "https://blogue.tech/feeds/a-novel-text-encryption-algorithm-using-enhanced-diffie-hellman-and-aes",
  ],
  $48: [
    "How to upgrade from Windows 7 to Windows 10",
    "https://blogue.tech/feeds/how-to-upgrade-from-windows-7-to-windows-10",
  ],
  $49: [
    "Node.js for Your Next Web App Project",
    "https://blogue.tech/feeds/node.js-for-your-next-web-app-project",
  ],
  $50: [
    "Activate Microsoft Office 2013 with KMSPico",
    "https://blogue.tech/feeds/activate-microsoft-office-2013-with-kmspico",
  ],
  $51: [
    "Technologies for Backend Development in 2021",
    "https://blogue.tech/feeds/technologies-for-backend-development-in-2021",
  ],
  $52: [
    "Linked List with JavaScript",
    "https://blogue.tech/feeds/linked-list-with-javascript",
  ],
  $53: [
    "Git : A short history",
    "https://blogue.tech/feeds/git-:-a-short-history",
  ],
  $54: [
    "Download Website Source Code with Nodejs",
    "https://blogue.tech/feeds/download-website-source-code-with-nodejs",
  ],
  $55: [
    "How WhatsApp Messenger Works",
    "https://blogue.tech/feeds/how-whatsapp-messenger-works",
  ],
  $56: [
    "A walkthrough to decentralized Internet",
    "https://blogue.tech/feeds/a-walkthrough-to-decentralized-internet",
  ],
  $57: [
    "Guide to Googling",
    "https://blogue.tech/feeds/guide-to-googling",
  ],
  $58: [
    "Trending Tech Stacks for Web Development",
    "https://blogue.tech/feeds/trending-tech-stacks-for-web-development",
  ],
};
