import React from "react";
import "./Blogs.scss";
import Window from "../components/Window";
import { useLocation } from "react-router-dom";
import pages from "../pages";
import {blogs,links} from "../data/blogs";
import { parseLinks } from "../_utils";

function Education() {
  const { pathname } = useLocation();
  return (
    <Window title={pages[pathname.slice(1)].title} bodyClass="window__resume">
      
    {blogs.map((resume, i) => (
        <div key={i} className="container">
          <div className="icon">{React.createElement(resume.icon)}</div>
          <div className="time">{resume.time}</div>
          <div className="degree">{parseLinks(resume.title,links)}</div>
          <div className="description">{resume.description}</div>
        </div>
      ))}
    </Window>
  );
}

export default Education;
