import {
  FaGraduationCap,
  FaCertificate,
  FaLaptop,
  FaBriefcase,
} from "react-icons/fa";

export const education = [
  {
    icon: FaGraduationCap,
    time: "2022 - 2024",
    title: "Engineering Management",
    place: "La Trobe University, Australia",
    description: "Master's Degree",
  }, {
    icon: FaGraduationCap,
    time: "2017 - 2021",
    title: "Information Technology and Engineering",
    place: "FET,Jain University,Bengaluru,India",
    description: "Bachelor's Degree",
  },
  {
    icon: FaGraduationCap,
    time: "2015 - 2017",
    title: "Science",
    place: "NIST,Kathmandu,Nepal",
    description: "Higher Secondary level",
  },
  {
    icon: FaGraduationCap,
    time: "2009 - 2015",
    title: "School",
    place: "RSS,Kathmandu,Nepal",
    description: "Secondary level",
  },
];

export const certificate = [
 {
    icon: FaLaptop,
    time: "August 2021",
    title: `$12`,
    place: "GOOGLE",
    description: "Coursera",
  },{
    icon: FaLaptop,
    time: "July 2021",
    title: `$11`,
    place: "GOOGLE",
    description: "Coursera",
  }, {
    icon: FaLaptop,
    time: "October 2020",
    title: `$1`,
    place: "INSEAD",
    description: "Coursera",
  },
  {
    icon: FaLaptop,
    time: "April 2020",
    title: `$2`,
    place: "The Hong Kong University of Science and Technology",
    description: "Coursera",
  },
  {
    icon: FaLaptop,
    time: "July 2020",
    title: `$3`,
    place: "Stanford University",
    description: "Coursera",
  },
  {
    icon: FaLaptop,
    time: "July 2020",
    title: `$4`,
    place: "MongoDb University",
    description: "MongoDb University",
  },
  {
    icon: FaLaptop,
    time: "August 2019",
    title: `$5`,
    place: "freeCodeCamp",
    description: "freeCodeCamp.org",
  },
  {
    icon: FaLaptop,
    time: "August 2019",
    title: `$6`,
    place: "freeCodeCamp",
    description: "freeCodeCamp.org",
  },
  {
    icon: FaLaptop,
    time: "May 2019",
    title: `$7`,
    place: "freeCodeCamp",
    description: "freeCodeCamp.org",
  },
];

export const publication = [
  {
    icon: FaCertificate,
    time: "May 2021",
    title: `$10`,
    place: "IJCSMC",
    description:
      "Proposed an encryption/decryption mechanism via a web application and storing & sharing data over cloud.",
  },
  {
    icon: FaCertificate,
    time: "April 2020",
    title: `$8`,
    place: "IJARCSMS",
    description:
      "Proposed an algorithm for text encryption where the Diffie-Hellman gets an update making it more secure, the algorithm proposed is implemented in Java.",
  },
  {
    icon: FaCertificate,
    time: "May 2020",
    title: `$9`,
    place: "IRJMETS",
    description:
      "This is a paper describing the theoretical concept of the RSA cryptosystem along with the implementation of the algorithm in JAVA.",
  },
];

export const links = {
  $1: [
    "Introduction to Blockchain Technologies",
    "https://www.coursera.org/account/accomplishments/certificate/SGZ6ER34B6CF",
  ],
  $2: [
    "Server-side Development with NodeJS, Express & MongoDB",
    "http://university.mongodb.com/course_completion/4a4c3b4e-73ae-4260-9d3e-8bee3f50f10c",
  ],
  $3: [
    "Cryptography-I",
    "https://www.coursera.org/account/accomplishments/certificate/LSQV9HT4FL9C",
  ],
  $4: [
    "MongoDB for Javascript Developers",
    "http://university.mongodb.com/course_completion/4a4c3b4e-73ae-4260-9d3e-8bee3f50f10c",
  ],
  $5: [
    "Front End Libraries",
    "https://www.freecodecamp.org/certification/sujanchhetri/front-end-libraries",
  ],
  $6: [
    "Javascript Algorithms And Data Structures",
    "https://www.freecodecamp.org/certification/sujanchhetri/javascript-algorithms-and-data-structures",
  ],
  $7: [
    "Responsive Web Design",
    "https://www.freecodecamp.org/certification/sujanchhetri/responsive-web-design",
  ],
  $8: [
    "A Novel Text Encryption Algorithm using enhanced Diffie Hellman and AES",
    "https://www.academia.edu/43523057/A_Novel_Text_Encryption_Algorithm_using_enhanced_Diffie_Hellman_and_AES",
  ],
  $9: [
    "RSA Algorithm: A theoretical study and implementation",
    "https://www.academia.edu/43154460/RSA_ALGORITHM_A_THEORETICAL_STUDY_AND_IMPLEMENTATION",
  ],
  $10: [
    "Secure File Storage & Sharing on Cloud Using Cryptography",
    "https://ijcsmc.com/docs/papers/May2021/V10I5202113.pdf",
  ],
  $11: [
    "Foundations of Project Management",
    "https://www.coursera.org/account/accomplishments/certificate/9VXPFYALBZJG",
  ],
  $12: [
    "Project Initiation: Starting a Successful Project",
    "https://www.coursera.org/account/accomplishments/certificate/2CYB8G54BJ9J",
  ],
};

export const skills = {
  web: {
    label: "Web Development",
    skills: [
      {
        name: "html",
        label: "HTML",
        value: 100,
        url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
        color: "#e34f26",
      },
      {
        name: "css",
        label: "CSS",
        value: 100,
        url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
        color: "#0170ba",
      },
      {
        name: "sass",
        label: "Sass",
        value: 100,
        url: "https://sass-lang.com/",
        color: "#cd6799",
      },
      {
        name: "bootstrap",
        label: "Bootstrap",
        value: 100,
        url: "https://getbootstrap.com/",
        color: "#563d7c",
      },
      {
        name: "reactjs",
        label: "ReactJS",
        value: 100,
        url: "https://reactjs.org/",
        color: "#00bcd4",
      },
      {
        name: "materialui",
        label: "Material-ui",
        value: 100,
        url: "https://material-ui.com/",
        color: "#1976d2",
      },
      {
        name: "jquery",
        label: "jQuery",
        value: 100,
        url: "https://jquery.com/",
        color: "#0868ac",
      },
      {
        name: "nodejs",
        label: "Nodejs",
        value: 100,
        url: "https://nodejs.org/en/",
        color: "#026e00",
      },
      {
        name: "mongodb",
        label: "Mongodb",
        value: 100,
        url: "https://www.mongodb.com/",
        color: "#13aa52",
      },
    ],
  },
  lang: {
    label: "Programming",
    skills: [
      {
        name: "typescript",
        label: "TypeScript",
        value: 100,
        url: "https://www.typescriptlang.org/docs",
        color: "#073cfa",
      },
      {
        name: "js",
        label: "JavaScript",
        value: 100,
        url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        color: "#ffc107",
      },
      {
        name: "cprogramming",
        label: "C",
        value: 100,
        url: "https://devdocs.io/c/",
        color: "#0b9e0b",
      },
      {
        name: "c++",
        label: "C++",
        value: 100,
        url: "https://devdocs.io/cpp/",
        color: "#0b9e0b",
      },
      {
        name: "java",
        label: "Java",
        value: 100,
        url: "https://docs.oracle.com/javase/8/docs/technotes/guides/language/index.html",
        color: "#5382a1",
      },
    ],
  },
  tools: {
    label: "Tools",
    skills: [
      {
        name: "vscode",
        label: "Visual Studio Code",
        value: 100,
        url: "https://code.visualstudio.com/",
        color: "#0075b7",
      },
      {
        name: "postman",
        label: "Postman",
        value: 100,
        url: "https://www.postman.com/api-documentation-tool/",
        color: "#8555bc",
      },
      {
        name: "office",
        label: "MS Office",
        value: 100,
        url: "https://www.office.com/",
        color: "#eb3c00",
      },
      {
        name: "digitalocean",
        label: "Digital Ocean",
        value: 100,
        url: "https://www.digitalocean.com/docs/",
        color: "#272770",
      },
      {
        name: "heroku",
        label: "Heroku",
        value: 100,
        url: "https://devcenter.heroku.com/categories/reference",
        color: "#82b64b",
      },
      {
        name: "gitt",
        label: "Git",
        value: 100,
        url: "https://www.git-scm.com/doc",
        color: "#333",
      },
      {
        name: "github",
        label: "Github",
        value: 100,
        url: "https://docs.github.com/en",
        color: "#333",
      },
      {
        name: "photoshop",
        label: "Photoshop",
        value: 100,
        url: "https://www.adobe.com/sea/products/photoshop.html",
        color: "#08004a",
      },
      {
        name: "canva",
        label: "Canva",
        value: 100,
        url: "https://www.canva.com/button/documentation/js-api/",
        color: "#39e6e0",
      },
    ],
  },
};
