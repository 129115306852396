import About from "./pages/About";
import Work from "./pages/Work";
import Blogs from "./pages/Blogs";
import Education from "./pages/Education";
import Podcast from "./pages/Podcast";
import Snips from "./pages/Snips";

export default {
  about: {
    title: "About",
    component: About,
  },
  work: {
    title: "Projects",
    component: Work,
  },
  blog: {
    title: "Blog",
    component: Blogs,
  },
  resume: {
    title: "Resume",
    component: Education,
  }
};
